import React, { useEffect, useState } from 'react';
import './Startups.css';
import HeaderSection from '../includes/HeaderSection/HeaderSection';
import Card from '../includes/Card/Card';
import { Link } from 'react-router-dom';
import startup from '../../assets/startup.png'
import { Main_API } from "../../env";
export default function Startups() {

  const [allstartups, setAllstartups] = useState([]);

  const getstartups = async() =>{
    fetch(Main_API + '/startups', {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },

  })
      .then((response) => response.json())
      .then((json) => {
        //console.log(json.success)
          if (json.success) {
            setAllstartups(json.startups);
          }
      })
  }

  useEffect(()=>getstartups, []);
  console.log(allstartups)
  return (
    <div id='startups-section' className='pb-10'>
      <HeaderSection subTitle={`Explore our portfolio of innovative startups.`} title={`Discover Our Range of Startups`} />
      <div className='services-wrapper grid md:grid-cols-3 xs:grid-cols-1'>
      {
        allstartups && allstartups.length > 0 && allstartups.map((item, key)=>(
          <Link to={`/startup/${item.id}`}>
          <Card pic={startup} delay="150" title={item.startup} desc={`${item.desc.slice(0, 100)}...`}/>
        </Link>
        ))
      }
       


      </div>
      <div className='btn'>
        <button className='p-2'>See More</button>
      </div>

    </div>
  )
}
